import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import Magnifier from "react-magnifier"
import Llanfyrnach from "../images/watercolours/llanfyrnach.jpg"
import TipiValley from "../images/watercolours/the-valley-garden.jpg"
import Preseli from "../images/watercolours/preseli.jpg"
import Carmarthen from "../images/watercolours/carmarthen.jpg"

const ImgContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;

  padding: 24px;
  cursor: pointer;
`

const ImgLayout = styled.div`
  grid-auto-columns: 1fr;
  max-width: 560px;
  margin: auto;

  @media (min-width: 1020px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 100%;
  }
`

const StyledMagnifier = styled(Magnifier)`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
`

const Painting = ({ src }) => {
  return (
    <ImgContainer>
      <StyledMagnifier src={src} width={"100%"} />
    </ImgContainer>
  )
}

const Watercolours = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sketchbook Drawings | Art by Izabelle Wilding</title>
        <meta name="Watercolour painting art by Izabelle Wilding" />
        <link rel="canonical" href="http://izabelleart.com/watercolours" />
      </Helmet>
      <Layout>
        <ImgLayout>
          <Painting src={Llanfyrnach} alt="Llanfyrnach by Izabelle Wilding" />
          <Painting src={TipiValley} alt="The garden by Izabelle Wilding" />
          <Painting src={Carmarthen} alt="Carmarthen by Izabelle Wilding" />
          <Painting src={Preseli} alt="Preseli by Izabelle Wilding" />
        </ImgLayout>
      </Layout>
    </>
  )
}

export default Watercolours
